import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import './styles.scss'

const Accordion = loadable(() => import('/src/components/Basic/Accordion/Accordion'))
const ContentDisclaimer = loadable(() => import('/src/components/Basic/ContentDisclaimer'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const AccordionBlock = ({ backgroundColour, titleArea, accordionRepeater, addSmallprint, smallprint }) => {
    return (
        <Section variant={backgroundColour} titleArea={titleArea} className={'c-accordion-block'}>
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    {accordionRepeater && (
                        <Accordion
                            data={accordionRepeater}
                            className={`c-accordion-block__accordion c-accordion-block__accordion--${backgroundColour}`}
                        />
                    )}

                    {addSmallprint && smallprint && <ContentDisclaimer content={smallprint} />}
                </div>
            </div>
        </Section>
    )
}

AccordionBlock.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string
    }),
    accordionRepeater: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired
        })
    ).isRequired,
    addSmallprint: PropTypes.bool,
    smallprint: PropTypes.string
}

AccordionBlock.defaultProps = {
    backgroundColour: 'light',
    titleArea: {
        addTitleArea: true,
        title: 'Section Title'
    },
    accordionRepeater: [
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        },
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        },
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        },
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        },
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        }
    ],
    addSmallprint: false,
    smallprint: '<p>I am some smallprint</p>'
}

export default AccordionBlock

export const query = graphql`
    fragment AccordionBlockForPageBuilder on WpPage_Acfpagebuilder_Layouts_AccordionBlock {
        fieldGroupName
        backgroundColour
        addTitleArea
        title
        description
        accordionRepeater {
            title
            content
        }
        addSmallprint
        smallprint
    }
`
